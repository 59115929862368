/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

const ReactQueryPreloader = ({ isLoading, isError, error, heightAuto }) => {
  if (isLoading) {
    return (
      <div className={`w-100 ${heightAuto ? 'h-100' : 'h-100vh'} d-flex center column linear-bg`}>
        <SyncLoader color="#4facfe" background="white" size={50} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={`w-100 ${heightAuto ? 'h-100' : 'h-100vh'} d-flex center column linear-bg m-0`}>
        <h1 className="text-blue-500">Error</h1>
        <span className="text-base">{error?.message || 'No data was received'}</span>
      </div>
    );
  }
  return (<></>);
};

export default ReactQueryPreloader;
