import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAtom } from 'jotai';
import { toastAtom, userAtom } from '../layout';

const LoginForm = () => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [toast] = useAtom(toastAtom);
  const [, checkUser] = useAtom(userAtom);
  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      console.log(inputs);
      const request = await firebase.auth().signInWithEmailAndPassword(inputs.email, inputs.password);
      checkUser({ id: request.user.uid, email: inputs.email });
      setLoading(false);
      return toast.current?.show({ severity: 'success', summary: 'Welcome! Successul login', detail: 'Just a sec, setting up a few things for you', life: 6000 });
    } catch (error) {
      setLoading(false);
      return toast.current.show({ severity: 'error', summary: 'Failed to login', detail: error.message, life: 6000 });
    }
  };
  return (
    <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div className="text-center mb-5">
        <img src="/1.png" alt="hyper" height={100} className="mb-3" />
      </div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
        <InputText type="text" className="w-full mb-3" required onChange={(ev) => setInputs({ ...inputs, email: ev.target.value })} />

        <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
        <InputText type="password" className="w-full mb-3" required onChange={(ev) => setInputs({ ...inputs, password: ev.target.value })} />

        <Button label="Sign In" icon="pi pi-user" className="w-full" type="submit" loading={loading} />
      </form>
    </div>
  );
};

export default LoginForm;
