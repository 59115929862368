import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import firebase from 'gatsby-plugin-firebase';
import { useQuery } from '@tanstack/react-query';
import SingleReferral from './SingleReferral';
import ReactQueryPreloader from '../../ReactQueryPreloader';

const ReferralsList = () => {
  const { isLoading, error, isError, data } = useQuery(['fetchReferrals'], async () => {
    try {
      const results = await firebase.firestore().collection('referrals').orderBy('timestamp', 'desc').get();
      const arr = [];
      results.forEach((key) => arr.push({ id: key.id, ...key.data() }));
      return arr;
    } catch (error) {
      throw new Error(error.message);
    }
  }, { onError: (error) => console.log(error) });
  if (isLoading || isError) {
    return (<ReactQueryPreloader isError={isError} isLoading={isLoading} error={error} />);
  }
  return (
    <div className="surface-section px-3 py-3">
      <div className="font-bold text-900 text-4xl mb-3">List of Referrals</div>
      <div className="p-3 border-round surface-50">
        <div className="flex flex-wrap">
          <div className="w-full p-1">
            <Accordion>
              {data?.map((key) => (
                <AccordionTab
                  key={key}
                  header={(
                    <div className="flex align-items-start column">
                      <span>{key.clientName}</span> <span className="text-700 text-xs font-light">{key.timestamp?.toDate()?.toDateString()} at {key.timestamp?.toDate()?.toTimeString()}</span>
                    </div>
    )}
                >
                  <SingleReferral details={key} />
                </AccordionTab>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralsList;
