import React from 'react';

const SingleReferral = ({ details }) => {
  return (
    <div className="surface-card p-4 shadow-2 border-round">
      <div className="font-medium text-3xl text-900 mb-3">Application Details</div>
      <div className="grid grid-nogutter border-top-1 surface-border pt-2">
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Client Name</div>
          <div className="text-900">{details.clientName}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Email</div>
          <div className="text-900">{details.email || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Phone Number</div>
          <div className="text-900">{details.phoneNumber}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Date of Birth</div>
          <div className="text-900">{details.dateOfBirth?.toDate()?.toDateString() || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Gender Preferred</div>
          <div className="text-900">{details.genderPreferred}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Language Preferred</div>
          <div className="text-900">{details.languagePreferred || ''}</div>
        </div>
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">Living Situation</div>
          <div className="text-900">{details.livingSituation}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">Diagnosis</div>
          <div className="text-900">{details.diagnosis}</div>
        </div>
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">Allergies</div>
          <div className="text-900">{details.allergies}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Smoker</div>
          <div className="text-900">{details.smoker?.label || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Pets</div>
          <div className="text-900">{details.pets?.label || ''}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Agency</div>
          <div className="text-900">{details.agency}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Case Manager Name</div>
          <div className="text-900">{details.caseManagerName}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Case Manager Phone</div>
          <div className="text-900">{details.caseManagerPhone}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Case Manager Email</div>
          <div className="text-900">{details.caseManagerEmail}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Emergency Contact's Name</div>
          <div className="text-900">{details.emergencyContactName || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Emergency Contact's Phone</div>
          <div className="text-900">{details.emergencyContactPhone || ''}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Recent Hospitalization</div>
          <div className="text-900">{details.recentHospitalization || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Language Preferred</div>
          <div className="text-900">{details.languagePreferred2 || ''}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Services Needed</div>
          <div className="text-900">{details.servicesNeeded || ''}</div>
        </div>
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Number of Hours/Week</div>
          <div className="text-900">{details.numberOfHoursOrWeeks || ''}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 md:col-6 p-3">
          <div className="text-500 font-medium mb-2">Anitcipated Start Date</div>
          <div className="text-900">{details.startDate?.toDate()?.toDateString() || ''}</div>
        </div>
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">Goals</div>
          <div className="text-900">{details.goals || ''}</div>
        </div>
        <div className="col-12 p-3">
          <div className="text-500 font-medium mb-2">Comments</div>
          <div className="text-900">{details.comments || ''}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleReferral;
