import React, { useRef, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import { useAtom } from 'jotai';
import { toastAtom, userAtom } from '../../layout';

const AdminNavBar = () => {
  const btnRef28 = useRef();
  const btnRef29 = useRef();
  const btnRef30 = useRef();
  const [user] = useAtom(userAtom);
  const [toast] = useAtom(toastAtom);
  const [loading, setLoading] = useState(false);
  const signOut = async (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      await firebase.auth().signOut();
      setLoading(false);
      return toast.current?.show({ severity: 'success', summary: 'See you next time', detail: '', life: 6000 });
    } catch (error) {
      setLoading(false);
      return toast.current.show({ severity: 'error', summary: 'Failed to login', detail: error.message, life: 6000 });
    }
  };
  return (
    <div className="surface-overlay shadow-2 flex relative lg:static justify-content-between" style={{ minHeight: '42px' }}>
      <StyleClass nodeRef={btnRef28} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
        <a ref={btnRef28} className="p-ripple cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700">
          <i className="pi pi-bars text-2xl" />
        </a>
      </StyleClass>
      <div className="hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul className="flex list-none p-0 m-0 flex-column lg:flex-row">
          <li className="h-full">
            <a className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-blue-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3">
              <span className="font-medium">Referrals</span>
              <Ripple />
            </a>
          </li>
          {/* <li className="h-full">
            <a className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3">
              <span className="pi pi-users mr-2" />
              <span className="font-medium">Customers</span>
              <Ripple />
            </a>
          </li> */}
        </ul>
      </div>
      <StyleClass nodeRef={btnRef29} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
        <a ref={btnRef29} className="p-ripple cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700">
          <i className="pi pi-ellipsis-v text-xl" />
        </a>
      </StyleClass>
      <div className="hidden lg:flex lg:justify-content-end absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul className="flex list-none p-0 m-0 flex-column lg:flex-row">
          <li className="h-full relative">
            <StyleClass nodeRef={btnRef30} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
              <a ref={btnRef30} style={{ minWidth: '150px' }} className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150">
                <i className="pi pi-user" />
                <span className="mx-2 font-medium text-900">{user.email ? user.email.split('@')[0] : null}</span>
                <i className="pi pi-angle-down" />
                <Ripple />
              </a>
            </StyleClass>
            <div className="hidden static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0">
              <ul className="list-none p-0 m-0">
                <li>
                  <a onClick={signOut} className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                    {loading ? <i className="pi pi-spin pi-spinner" /> : null}
                    <span className="pi pi-sign-out mr-2" />
                    <span className="font-medium">Sign Out</span>
                    <Ripple />
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminNavBar;
