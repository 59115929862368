import React, { useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useAtom } from 'jotai';
import { userAtom } from '../layout';
import LoginForm from './LoginForm';
import AdminNavBar from './admin-navbar/AdminNavBar';
import ReferralsList from './referrals/ReferralsList';

const Auth = () => {
  const [user, checkUser] = useAtom(userAtom);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // This where we confirm if a user is logged in or not
      if (user) {
        checkUser({ id: user.uid, email: user.email });
      } else {
        checkUser({});
      }
    });
    return () => null;
  }, []);
  return (
    <>
      {user.id ? (
        <>
          <AdminNavBar />
          <ReferralsList />
        </>
      ) : <div className="w-100 py-3 flex center"><LoginForm /></div>}
    </>
  );
};

export default Auth;
