import React from 'react';
import Auth from '../components/auth/Auth';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHeader from '../components/services/service-page/PageHeader';

const AdminPage = () => {
  return (
    <Layout>
      <PageHeader title="Administration" />
      <Auth />
    </Layout>
  );
};

export default AdminPage;

export const Head = () => (
  <Seo title="Admin" />
);
